.header__breadcrumb {
  margin-bottom: 25px;
  text-transform: capitalize;
  .ant-breadcrumb-link {
    text-transform: capitalize;
  }
  .active-link {
    color: @primary-color;
  }
  .ant-breadcrumb > span:last-child a {
    color: @primary-color;
  }
  h1 {
    // margin-bottom: 10px;
    margin-right: 20px;
    font-size: 24px;
    // line-height: 28px;
  }
}

.ant-breadcrumb {
  display: flex;
  flex-wrap: wrap;
}
