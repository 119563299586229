.payment__skeleton {
  .ant-skeleton-avatar-circle {
    height: 90px;
    width: 90px;
  }
  .ant-skeleton-button {
    width: 150px;
    &.text {
      width: 400px;
      height: 17px;
    }
  }
}

.notification__list {
  .skeleton-container {
    width: 100%;
    .ant-skeleton {
      width: 100%;
      .ant-skeleton-button {
        width: 80%;
        height: 20px;
        border-radius: 10px;
      }
      &.short {
        .ant-skeleton-button {
          width: 50%;
          height: 20px;
        }
      }
    }
  }
}

.main-skeleton {
  .ant-layout-sider {
    top: 64px !important;
    padding: 0px 20px;
    @media @lg {
      display: none;
    }
  }
  .ant-layout-content {
    display: flex;
    justify-content: flex-end;
  }
  .ant-layout-header {
    background-color: @component-background;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-card-bordered {
    margin: 20px;
    width: calc(100vw - 300px);
    @media @lg {
      width: 100%;
    }
  }
}
