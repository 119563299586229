.ant-progress-circle {
  .ant-progress-text {
    font-size: 22px;
    font-weight: 500;
  }
}

.ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #2972ea;
}
