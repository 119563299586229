.error__page__wrapper {
  .page__inside {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media @sm {
      padding: 0px 20px;
      svg {
        width: 100%;
      }
    }
    .ant-typography {
      display: block;
      margin-top: 45px;
      font-size: 18px;
      @media @sm {
        margin-top: 0px;
        font-size: 13px;
      }
    }
    button {
      font-weight: 500;
      font-size: 16px;
      @media @sm {
        font-size: 14px;
      }
    }
  }
}
