.page__wrapper {
  margin: 70px 0 0 250px;
  transition: all 0.3s ease;
  padding: 30px;
  &.expanded {
    margin-left: 80px;
    @media @lg {
      margin-left: 0;
    }
  }
  @media @lg {
    margin-left: 0;
  }
  @media (max-width: 575px) {
    padding: 20px;
  }
  .card-title {
    font-weight: 500;
    font-size: 18px;
  }
}

.policy-table-container,
.remaining-card-wrapper {
  .header {
    border-bottom: 1px solid @border-color;
    padding-bottom: 10px;
    padding-right: 24px;
    padding-left: 24px;
  }
}

.dashboard-card-wrapper {
  .ant-card-body {
    h2 {
      font-size: 32px;
      color: #455560;
    }
    svg {
      font-size: 36px;
      color: #455560;
    }
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.remaining-card-wrapper {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 19px 0px 29px 0px;
    .header {
      width: 100%;
      text-align: center;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
      .text {
        width: 195px;
      }
      .bottom {
        margin-top: 15px;
      }
    }
  }
}

// .ant-tag {
//   display: flex;
//   align-items: center;
// }
