.travel-wrapper {
  .travel-plan-card {
    width: 100%;
    .card-wrapper {
      border: 1px solid @border-color;
      padding-bottom: 15px;
      .card-header {
        padding: 10px;
        display: flex;
        justify-content: center;
        background: @red-base;
        align-items: center;
        .ant-typography {
          color: @white;
        }
        &.standard {
          background: @blue-base;
        }
      }
      ul {
        padding: 0px 13px;
        li {
          padding-top: 20px;
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          span {
            margin-right: 7px;
            line-height: 21px;
          }
        }
      }
    }
  }
  .review-card {
    padding: 20px;
    border: 1px solid @border-color;
    display: flex;
    flex-direction: column;
    .title {
      .ant-typography.ant-typography-danger {
        font-size: 16px;
      }
    }
    .destination {
      color: @primary-color;
      font-size: 19px;
      font-weight: 500;
    }
    .date-title {
      font-size: 17px;
    }
    .date-value {
      display: flex;
      align-items: center;
      span {
        margin-right: 8px;
      }
    }
    table {
      td {
        padding: 5px 0px;
      }
    }
    ul {
      li {
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        span {
          margin-right: 7px;
          line-height: 21px;
        }
      }
    }
  }
  .ant-form-item-label > label {
    align-items: flex-start;
  }
}

.family__form__modal__container {
  width: 70% !important;
}

.family__form__table {
  border: 1px solid @border-color;
  width: 100%;
  .ant-form-item {
    margin: 0;
  }
  tr {
    text-align: left;
  }
  th {
    padding: 7px;
  }
  td {
    padding: 7px;
    border: 1px solid @border-color;
    // width: 475px;
    textarea {
      resize: none;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    display: none !important;
  }
  .ant-upload-list-picture-card-container {
    height: 60px;
    width: 60px;
  }
}



.custom-table {
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.5);
  // width: 97%;
  overflow: hidden;
  margin: 0 auto;

  table {
    // width: 100%;
    border-collapse: collapse;
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 3em;
    min-width: 3em;
    max-width: 3em;
    word-break: break-all;
  }

  th,
  td {
    text-align: center;
    padding: 15px;
    width: calc(100% / 5);
  }

  thead {
    tr {
      th,
      td {
        color: #ffffff;
        font-weight: bold;
      }

      &:nth-child(1) {
        background-color: #e91e63;
        th {
          font-size: 1.3rem;
          text-transform: uppercase;
        }
      }

      &:nth-child(2) {
        background-color: #c13a68;
        td {
          font-size: 0.9rem;
          padding: 20px 15px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
      }

      th:nth-child(1) {
        color: #ffffff;
        background-color: #010306;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      th:nth-child(2) {
        color: #ffffff;
        background-color: #041120;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      th:nth-child(3),
      td:nth-child(1) {
        background-color: rgba(0, 0, 0, 0.1);
      }
      th:nth-child(4),
      td:nth-child(2) {
        background-color: rgba(0, 0, 0, 0.2);
      }
      th:nth-child(5),
      td:nth-child(3) {
        background-color: rgba(0, 0, 0, 0.3);
      }
      th:nth-child(6),
      td:nth-child(4) {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #e0e0e0;
      }

      &:nth-child(even) {
        background-color: #eeeeee;
      }

      &:not(:last-child) td {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      td {
        &:nth-child(odd) {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:first-child {
          color: #ffffff;
          background-color: #041120;
          font-weight: bold;
        }
        &:nth-child(2) {
          color: #ffffff;
          background-color: #071f3a;
          font-weight: bold;
        }
      }
    }
  }
}

.priceCompareNote {
  background-color: #fff6f7;
  padding: 15px 25px;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 1px 15px -3px rgb(36 161 245 / 20%);
}

.priceCompareNote p {
  color: #f45;
  margin: 5px 0;
  font-size: 18px;
}

.priceCompareNote ul {
  padding-left: 20px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.priceCompareNote ul li {
  color: #f45;
  font-size: 14px;
  list-style: disc;
}

.custom-table.country-table {
  thead tr th:first-child,
  tbody tr td:first-child {
    width: 4%;
    min-width: 2.8em;
    max-width: 2.8em;
    word-break: break-all;
  }
}

@media only screen and (max-width: 720px) {
  /* For mobile phones: */

  .custom-table {
    overflow-x: auto;
  }
}

.travel-insurance {
  display: flex;
  justify-content: space-between;
  background-color: #355692;
  // background-color: #68102e;
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 16px;
  
  h5{
    text-shadow: 1px 1px 0 black;
    transition: text-shadow 0.3s ease;
  }
  &:hover{
    box-shadow: 1px 1px 2px black;    
    transform: scale(0.99);  
    transition: all 0.5s ease ;      
  }
}

.travel-insurance h4:hover{
  text-shadow: 2px 2px 3px black;              
}

.travel-btn {
  padding: 5px 20px;
  width: 7rem;
  flex: 0 0 auto;
  // box-shadow: 1px 1px 0 black;
    transition: box-shadow 0.3s ease;
}
.travel-btn:hover {
  // border-color: gray;
  // border-width: 2px;
  box-shadow: 2px 2px 3px black;   
}

@media only screen and (max-width: 620px) {
  .travel-insurance {
    h4 {
      font-size: 1.2rem;
    }
  }
}
