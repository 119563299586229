.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-left: 11px !important;
  &::after {
    display: none !important;
  }
  &.success-outlined-btn {
    // background-color: @lime-base;
    border-color: @lime-base;
    color: @lime-base;
  }
  &.outlined-btn {
    border-color: @primary-color;
    color: @primary-color;
  }
  &.dander-outlined-btn {
    border-color: @red-base;
    color: @red-base;
  }
  &.success-btn {
    background-color: @lime-base;
    color: @white;
    border-color: @lime-base;
  }
  &.green-btn {
    background-color: @green-btn;
    color: @white;
    border-color: @green-btn;
    border-radius: 6px;
    height: 40px;
  }
  &.blue-btn {
    background-color: @primary-color;
    color: @white;
    border-color: @primary-color;
    border-radius: 6px;
    height: 40px;
  }
  &.green-btn-dash {
    background-color: @lime-base;
    color: @white;
    border-color: @lime-base;
    border-radius: 6px;
    height: 40px;
  }
  &.login__btn {
    background-color: #2a59d1;
    border-color: #2a59d1;
    border-radius: 0.625rem;
    color: @white !important;
    height: 40px;
    &:hover {
      background-color: #699dff;
      border-color: #699dff;
    }
  }
  &.learn__more__btn {
    background-color: #2a59d1;
    border-color: #2a59d1;
    border-radius: 0.625rem;
    color: @white !important;
    height: 40px;
    padding: 8px 45px;
    margin-top: 20px;
    &:hover {
      background-color: #699dff;
      border-color: #699dff;
    }
  }
  // &.ant-btn-default{
  //     background-color: @sidebar-toggle-btn-color !important;
  //     border-color: @sidebar-toggle-btn-color !important;
  //     color: @white;
  //     &:hover{
  //         background-color: lighten(@sidebar-toggle-btn-color, 5%) !important;
  //     }
  // }
}

.btn__menu__toggle {
  // color: #455560;
  // border: none;
  margin-left: 2rem;

  // background-color: transparent !important;
  // border-radius: 0;
  // font-size: 22px;
  // padding: 1rem;
  // margin-right: 1rem;
  // @include flexAlignCenter;
  // animation-duration: 0s;
  // outline: none;
  // box-shadow: none !important;
  // &:hover {
  //   color: @primary-color;
  // }
  @media @lg {
    display: none;
  }
}

.icon-btn {
  border: none;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  box-shadow: 0px 3px 6px @box-shadow;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 16px;
    width: 16px;
    transform: translate(-50%, -37%);
    g {
      stroke: @menu-item-color;
      fill: @menu-item-color;
    }
    path {
      stroke: @menu-item-color;
    }
  }
  .ant-badge {
    position: absolute !important;
    right: -5px;
    top: 5px;
  }
}
.drawer__trigger {
  display: none;
  @media @lg {
    display: block;
  }
}
.connect__options {
  button {
    border-radius: 0.625rem;
    border: 1px solid #e6ebf1;
    color: #455560 !important;
    height: 40px;
    img {
      margin-right: 0.5rem;
    }
    &:hover {
      border-color: #e6ebf1;
      background-color: #e6ebf1;
    }
    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
.btn__search__sm {
  background-color: transparent !important;
  border: none;
}

.full__width {
  .ant-upload {
    width: 100%;
  }
}

.upload-input-btn {
  justify-content: left;
  padding-left: 13px !important;
  &:hover {
    background: none;
    color: @text-color-secondary;
    // border-color: @text-color-secondary;
  }
  &:active {
    background: none;
    color: @text-color-secondary;
    // border-color: @text-color-secondary;
  }
  &:focus {
    background: none;
    color: @text-color-secondary;
    // border-color: @text-color-secondary;
  }
}

.view__icon {
  font-size: 17px !important;
}
