.calculation-container {
  .ant-row .ant-form-item {
    margin: 0px;
  }
}

.calculation-summary-container {
  .ant-card-body {
    padding: 0px;
    .title {
      font-size: 20px;
      font-weight: 500;
      padding: 12px 20px;
      border-bottom: 1px solid @border-color;
    }
    .calculation-table {
      padding: 12px 20px 30px;
    }
  }
}

.summary-table {
  width: 100%;
  th {
    border: 1px solid @border-color;
    padding: 10px;
    font-size: 16px;
  }
  td {
    padding: 10px;
    border: 1px solid @border-color;
  }
}

@media screen and (max-width: 1024px) {
  .calculateTableDetails {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
