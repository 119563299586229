.profile-page-wrapper {
  .profile__top__container {
    display: flex;
    .short__desc {
      flex: 1;
      padding-left: 35px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      .ant-typography {
        margin-bottom: 5px;
      }
      @media @sm {
        align-items: center;
        justify-content: center;
        padding-left: 0px;
      }
    }
    figure {
      position: relative;
      img {
        height: 130px;
        width: 130px;
        border-radius: 65px;
        object-fit: contain;
      }
    }
    .profile__upload__btn {
      position: absolute;
      height: 40px;
      width: 40px;
      background-color: #fff;
      z-index: 9;
      left: 70%;
      bottom: 0;
      display: block;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        color: @primary-color;
        font-size: 20px;
      }
    }
    @media @sm {
      flex-direction: column;
      align-items: center;
    }
  }
  .profile__detail {
    table {
      width: 100%;
      tr {
        border-bottom: 1px solid @border-color;
        text-align: left;
        padding-bottom: 1rem;
        td {
          padding-left: 30px;
        }
        th {
          width: 200px;
          font-weight: 500;
        }
      }
    }
  }
  .change-password-container {
    width: 600px;
    @media @sm {
      width: 100%;
    }
  }
  .otp-form-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow-x: auto;
  }
  .img-container {
    .ant-image {
      margin-right: 20px;
      img {
        height: 200px !important;
        width: 200px !important;
        object-fit: cover;
        :hover{
          cursor: pointer !important;
          -webkit-transform: scale(1.3) !important;
          transform: scale(1.1) !important;
        }
      }
      
    }

    img {
      height: 200px !important;
      width: 200px !important;
      object-fit: cover;
      :hover{
        cursor: pointer !important;
        -webkit-transform: scale(1.3) !important;
        transform: scale(1.1) !important;
      }
    }
  }
}

.upload-modal-body {
  height: 105px;
}

.uploaded-files img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}
.uploaded-files img:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.1);
}
