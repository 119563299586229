.ant-input,
.ant-input-password,
.ant-form-item-control-input-content {
  border-radius: 4px;
  height: 42px;
}
.search__wrapper {
  .ant-input {
    min-width: 300px;
  }
}
.ant-form {
  .ant-cascader-picker {
    border-radius: 12px;
  }
  .ant-input-password {
    .ant-input {
      height: auto;
      border-radius: 0;
    }
  }
  .ant-select-selector {
    height: 42px !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    .ant-select-selection-search {
      top: unset;
    }
  }
  .ant-picker {
    height: 42px !important;
    border-radius: 4px;
  }
  .ant-picker-panel-container {
    border-radius: 12px;
    overflow: hidden;
  }
}
.ant-select-dropdown {
  border-radius: 0.625rem;
}
.ant-input-group-addon {
  border-radius: 0.625rem;
}
.signup {
  .ant-form-item-control-input-content {
    display: flex;
  }
  .center {
    .ant-form-item-control-input-content {
      display: block;
    }
  }
  .ant-select-selector {
    text-align: initial;
  }
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  top: 4px !important;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.ant-form-item-label {
  label {
    & > div {
      display: flex;
      align-items: center;
      span {
        font-size: 15px;
        margin-left: 7px;
        color: @geekblue-base;
        cursor: pointer;
      }
    }
  }
}
.tooltip-container {
  // margin-bottom: 2px;
  color: @geekblue-base !important;
  cursor: pointer !important;
}

.tooltip-container {
  // margin-bottom: 2px;
  span {
    font-size: 15px;
    margin-left: 7px;
    color: @geekblue-base;
    cursor: pointer;
  }
}

.ant-tooltip-inner {
  background: @blue-base;

  // .ant-tooltip-arrow {
  //   background: alpha(@blue-base, 0.5);
  // }
}

.tooltip-checkbox {
  > span:nth-child(2) {
    svg {
      margin-bottom: 5px;
      color: @geekblue-base;
    }
  }
}

.ant-input,
.ant-input-password,
.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  padding-left: -1px;
}

.ant-upload-list-item-actions {
  display: flex;
  align-items: flex-end;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  overflow-y: auto;
}

.ant-select-dropdown {
  // z-index: 1;
}

.ant-form-item-control-input-content {
  height: auto;
  .ant-select-selector {
    height: auto !important;
    max-height: 100px !important;
    min-height: 42px;
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: @disabled-text-color;
}

.ant-input[disabled] {
  color: @disabled-text-color;
}

.ant-picker-input > input[disabled] {
  color: @disabled-text-color;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: @disabled-text-color;
}
.ant-checkbox-disabled + span {
  color: @disabled-text-color;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: @disabled-text-color !important;
}

.ant-radio-disabled .ant-radio-inner::after {
  background-color: @disabled-text-color;
}

.ant-radio-disabled + span {
  color: @disabled-text-color;
}

.guest-route-style{
  margin: 8px;
  padding: 0 30px 10px;
}