.kyc-form-wrapper {
  .check-box-text {
    font-size: 13px;
    font-weight: 500;
    display: inline;
  }
  .upload-btn {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    .anticon {
      cursor: pointer;
    }
  }
}

.upload-modal {
  .ant-skeleton-element .ant-skeleton-button {
    height: 120px;
    width: 120px;
  }
}

.upload-btn {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .anticon {
    cursor: pointer;
  }
}

.datepicker-dark{
  width: 100%;
  input{
    background-color: #30304d !important;
    color: #fff !important;
  }
  .calender{
    background: #30304d none repeat scroll 0 0 !important;
    color: #fff !important;
    padding-left: none !important;
    padding-right: none !important;
    padding-top: none !important;
    padding-bottom: none !important;
    // box-shadow: ;
  }

  .calendar-wrapper {
    background: #30304d none repeat scroll 0 0 !important;
    color: #fff !important;
    width: 100%;
    height: 100%;
  }
}