.table-responsive {
  max-width: 100%;
  overflow-x: auto;
}
.action__btn__wrapper {
  .ant-btn {
    margin-right: 10px;
    text-transform: capitalize;
    &:last-child {
      margin-right: 0;
    }
  }
}

// .ant-table-tbody > tr > td {
//   border-bottom: 0px solid #f0f0f0;
//   }
.ant-row > .ant-divider-horizontal.ant-divider-with-text::before,
.ant-row > .ant-divider-horizontal.ant-divider-with-text::after {
  top: 0;
}
.table th,
.table td {
  border-right: 1px solid;
}
.ant-table {
    // background: @component-background !important;
  border: 1px solid @border-color !important;
}
.ant-table-thead > tr > th {
    // background: @component-background !important;
  border-bottom: 1px solid @border-color !important;
  border-left: 1px solid @border-color !important;
  //   color: $secondary-color;
  font-weight: 600;
}
.ant-table-thead > tr > th:first-child {
  border-left: 0 !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid @border-color !important;
  border-left: 1px solid @border-color !important;
  //   color: $secondary-color;
  // text-align: right;
}
.ant-table-tbody > tr > td:first-child {
  border-left: 0 !important;
}

.table-lc{
  .ant-table-tbody
  {
    tr{
      td:last-child{
        background-color: #fff !important;
      }
    }

  }
  
}

.table-lc .dark{
  .ant-table-tbody
  {
    tr{
      td:last-child{
        background-color: #30304d !important;
      }
    }

  } 
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 15px;
}

.table-responsives {
  .ant-table-content {
    overflow-x: auto;
  }
  table {
    overflow-x: auto;
  }
}
.expandad__table {
  > .ant-table-cell {
    padding: 0;
    .ant-table {
      margin: 0 !important;
      border: none !important;
      // background-color: @component-background !important;
    }
  }
}

.table-form {
  .ant-input,
  .ant-input-password,
  .ant-input-number-input {
    min-height: 32px;
    padding: 5px 7px;
  }
}

.amount-cell {
  input {
    text-align: end;
  }
}

.total-amount-footer {
  text-align: end;
}

.table-footer {
  .ant-form-item {
    margin-bottom: 0px;
  }
}
.table-responsives {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }
}
.table-container-responsive {
  width: 100%;
  overflow-x: auto;
  @media @md {
    table {
      tr {
        td {
          min-width: 200px;
          &:first-child {
            min-width: unset;
          }
        }
      }
    }
  }
}

.iti-table-container .table {
    border: 1px solid @border-color;
    width: 100%;
    .ant-form-item {
      margin: 0;
    }
    tr {
      text-align: left;
    }
    th {
      padding: 7px;
    }
    td {
      padding: 7px;
      border: 1px solid @border-color;
      // width: 475px;
      textarea {
        resize: none;
      }
    }
  }

  .ant-table-body{
    background-color: #fff !important;
  }