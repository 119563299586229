.create-policy-wrapper {
  .category-container {
    &.container-padding {
      padding: 20px 54px 60px 54px;
    }
    .category-card-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      transition: scale 0.5s;
      position: relative;
      &:hover {
        box-shadow: 0px 3px 44px @box-shadow;
        .icon-container {
          transform: scale(1.1);
        }
      }
      .anticon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 17px;
      }
      &.active {
        border: 2px solid @green-base;
        border-radius: 10px;
      }
      padding: 40px 20px;
      align-items: center;
      .icon-container {
        transition: all 0.3s;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .w__70 {
        width: 70px !important;
      }
      // &.disabled {
      //   background-color: #eee;
      //   cursor: not-allowed;
      // }
    }
  }
  .ant-card-body {
    padding: 0px;
    .title {
      border-bottom: 1px solid @border-color;
      display: block;
      font-weight: 500;
      font-size: 18px;
      padding: 15px 0px;
    }
  }
}

.payment-card-container {
  img {
    width: 120px !important;
  }
  // &:hover {
  //   img {
  //     transform: scale(1.1);
  //   }
  // }
}

.payment-card-container {
  .ant-card-body {
    padding: 0px 0px 60px 0px;
  }
}

.motorbike {
  background: #f3e2fd;
}
.privateVehicle {
  background: #f9d3d6;
}
.travel {
  background: #d1fbfa;
}
.personalAccident {
  background: #fdfab2;
}
.phi {
  background: #d6edfd;
}
.others {
  background: #e2e2e2;
}
.agriculture {
  background: #d7f8d2;
}
.home {
  background: #fbf3db;
}
.iti {
  background: #ffd9ce;
}

.policy-filter {
  width: 17.5rem;
  font-size: 14px;
}
