.product-description-content {
  overflow-y: scroll;
  max-height: 450px;
}

.product-description-card {
  transition: transform 0.5s ease;
  .image {
    transition: transform 0.5s ease;
    border-radius: 7px;
  }
  &:hover {
    box-shadow: 0px 3px 44px @box-shadow;
    .image {
      transform: scale(1.07);
    }
  }
}

.product-description-card .text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.read-more-btn {
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 90px;
  color: #0842a1;
  font-weight: 500;
  transition: transform 0.5s ease;

  &:hover {
    color: #699dff;
    transform: translateX(7px);
  }
}

.read-more-btn-icon {
  font-size: 10px;
  margin-left: 3px;
}
