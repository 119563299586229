.ant-card {
  border-radius: 0.635rem;
  margin-bottom: 20px;
  .revenue__amount {
    // @include flex-center(flex, center, flex-start);
    h1 {
      margin-right: 1rem;
      font-weight: @bold;
      font-size: 30px;
    }
    span {
      font-size: @font-md;
      font-weight: @bold;
    }
  }
  h3 {
    font-size: @font-md;
    font-weight: @medium;
    margin-bottom: 1rem;
  }
  p {
    font-size: @font-14;
    margin-top: 0.5rem;
  }
  .ant-progress {
    margin-bottom: 0.5rem;
  }
  .target__texts {
    max-width: 200px;
    margin: auto;
  }
  .policies-table {
    .ant-table {
      .ant-table-content {
        th {
          &.ant-table-cell {
            background-color: @white !important;
            white-space: nowrap;
            &::before {
              display: none;
            }
          }
        }
        td {
          &.ant-table-cell {
            background-color: @white !important;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .policies-table-dark {
    .ant-table {
      .ant-table-content {
        th {
          &.ant-table-cell {
            background-color: #30304d !important;
            white-space: nowrap;
            &::before {
              display: none;
            }
          }
        }
        td {
          &.ant-table-cell {
            background-color: #30304d !important;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .ant-table {
    // background-color: @component-background !important;
    .ant-table-content {
      th {
        &.ant-table-cell {
          // background-color: @component-background !important;
          white-space: nowrap;
          &::before {
            display: none;
          }
        }
      }
    }
    td {
      &.ant-table-cell {
        // background-color: @component-background !important;
        white-space: nowrap;
      }
    }
  }
  // .ant-avatar{
  //     background-color: @cyan;
  // }
}

.custom-card {
  max-height: 120px;
  &.ant-card {
    border-radius: 5px;
  }
  .ant-card-body {
    display: flex;
  }
  .text-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    .title {
      font-size: 17px;
      font-weight: 500;
    }
    .count {
      font-size: 30px;
      font-weight: 500;
    }
    .summary {
      display: flex;
      align-items: center;
      font-size: 15px;
      span {
        display: block;
        margin-top: 3px;
      }
      .number {
        font-weight: 400;
        color: @primary-color;
        margin-right: 3px;
        &.orange {
          color: #f36c4c;
        }
        &.pink {
          color: #c166d3;
        }
        &.blue {
          color: #2fc3d2;
        }
      }
      img {
        height: 15px;
        width: 14px;
        margin-right: 4px;
      }
    }
  }
  .right-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    img {
      height: 82px;
      width: 82px;
    }
  }
}

.policy-table-container {
  .ant-card-body {
    padding: 12px 0px;
  }
}

.payment-card-container {
  .ant-row {
    padding: 0px 30px;
  }
}
