.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.dob-modal{
  width: 900px;
  height: 600px;
}

.modal-row{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}