.terms-and-conditions {
  ol {
    counter-reset: item;
  }
  ol li {
    display: block;
    position: relative;
    margin-top: 10px;

    p{
      text-align: justify;
      text-justify: inter-word;
    }
  }
  ol li:before {
    content: counter(item, decimal) ". ";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 10px; /* space between number and text */
  }
  .type-I li:before{
    content: counter(item, upper-roman) ") ";
    counter-increment: item;
  }
  .type-i li:before{
    content: counter(item, lower-roman) ") ";
    counter-increment: item;
  }
}

.terms-first{
  font-weight: bold;
}