.opt__input {
  height: 50px;
  width: 50px !important;
  font-size: 26px;
  border: 1px solid @border-color;
  outline: none;
  background-color: @login-bg;
}

.otp__input_container > div {
  height: 50px;
  width: 50px;
  margin-right: 20px;
  @media @sm {
    width: 40px;
    margin-right: 10px;
    height: 40px;
    input {
      height: 40px;
      width: 40px !important;
    }
  }
}

.otp__input_container {
  justify-content: center;
}
