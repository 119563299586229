p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}

.w__100 {
  width: 100% !important;
}
.w__50 {
  width: 50% !important;
}
.h__100 {
  height: 100% !important;
}
