.phi-table-form {
  border: 1px solid @border-color;
  width: 100%;
  .ant-form-item {
    margin: 0;
  }
  tr {
    text-align: left;
  }
  th {
    padding: 7px;
    font-weight: 500;
  }
  td {
    padding: 7px;
    border: 1px solid @border-color;
    min-width: 150px;
    textarea {
      resize: none;
    }
  }
  td:nth-child(1) {
    min-width: 5px;
  }
  td:nth-child(4) {
    min-width: 65px;
  }
  td:last-child {
    min-width: 50px;
  }
  // .ant-form-item-explain.ant-form-item-explain-error {
  //   display: none !important;
  // }
  .ant-upload-list-picture-card-container {
    height: 60px;
    width: 60px;
  }
}
