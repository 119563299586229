@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import 'antd/dist/antd.less';
@import './abstracts/_variable.less';
@import './abstracts/_lightvariable.less';
// @import "./abstracts/_darkvariable.less";
@import './abstracts/_media.less';

@import './base/_base.less';
@import './base/_typography.less';
@import './base/_helpers.less';

@import './component/_button.less';
@import './component/_modal.less';
@import './component/_form.less';
@import './component/_card.less';
@import './component/_table.less';
@import './component/_pagination.less';
@import './component/_breadcrumb.less';
@import './component/_progress.less';
@import './component/_skeleton.less';

@import './layout/_navbar.less';
@import './layout/_sidebar.less';
@import './layout/_navdrawer.less';

@import './pages/_home.less';
@import './pages/_login.less';
@import './pages/_daily.less';
@import './pages/_register.less';
@import './pages/_error.less';
@import './pages/_error-boundary.less';
@import './pages/_profile.less';
@import './pages/_otp.less';
@import './pages/_kyc-form.less';
@import './pages/_create-policy.less';
@import './pages/_home-from.less';
@import './pages/_calculation.less';
@import './pages/_travel-form.less';
@import './pages/_resultPage.less';
@import "./pages/_terms-conditions.less";

@import './vendor/_slick.less';
@import './pages/_policy_form.less';
@import './pages/_phi-form.less';
@import './pages/_agriculture.less';
@import './pages/_reprint.less';
@import './pages/_faq.less';
@import './pages/_product-description.less';

// @import 'pages/_media.scss';

body {
  font-family: 'Roboto', sans-serif;
  background: @bg-color;
}

@font-face {
  font-family: testfont;
  src: url('/assets/preeti.otf') format('opentype');
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px @primary-color, 0 0 5px @primary-color;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: @primary-color;
  border-left-color: @primary-color;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: @autofill-color !important;
  caret-color: @autofill-color !important;
}

input {
  caret-color: @autofill-color !important;
}
// @import 'pages/_media.scss';
