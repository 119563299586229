.home_form_wrapper {
  table {
    border: 1px solid @border-color;
    width: 100%;
    .ant-form-item {
      margin: 0;
    }
    tr {
      text-align: left;
    }
    th {
      padding: 7px;
    }
    td {
      padding: 7px;
      border: 1px solid @border-color;
      // width: 475px;
      textarea {
        resize: none;
      }
    }
  }
  .ant-collapse-header {
    display: flex;
    align-items: center;
    .header__container {
      width: 100%;
      align-items: center;
      .ant-typography {
        flex: 1;
      }
    }
  }
}
