.authentication__wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: url('../images/authentication-bg.jpg')no-repeat center/cover;
  background-color: @login-bg;
  text-align: center;

  .login_text{
    font-size: 18px;
  }

  .signup_btn  {
    // display: inline-block;
    border-color: #42b72a;
    background-color: #42b72a;
    color: @white;
    :hover{
      border-color: #42b72a;
    }
  }

  @media @md {
    width: 100%;
  }
  .authentication__content {
    background-color: transparent;
    padding: 20px;
    border-radius: 4px;
    // border: 1px solid #e6ebf1;
    width: 600px !important;
    margin: 0 auto;

    @media @md {
      width: 100%;
    }
      img{
        width: 100%;
        margin-bottom: 2rem;
      }
      .input__groups {
        margin-bottom: 1.5rem;
        width: 100%;
      }

      .form__wrapper{
        margin: 1.5rem auto;
        width: 80%;

        >div{
          div.ant-typography{
            font-size: 16px;
          }
        }
      }
    &.signup {
      width: 700px;
      > div {
        width: 700px;
      }
      @media @sm {
        width: 100%;
        > div {
          width: 100%;
        }
      }
    }
    .alert__wrapper {
      padding: 15px 0;
      text-align: left;
      .ant-alert-message {
        color: #e02727 !important;
      }
      .ant-alert-close-icon {
        position: absolute;
        opacity: 0;
        border-radius: 50%;
        height: 15px;
        width: 16px;
        left: 6px;
        overflow: hidden;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    // .login_btn_container {
    //   button {
    //     font-weight: 600;
    //     font-size: 15px;
    //   }
    // }
    .connect__wrapper {
      margin-bottom: 16px;
      span {
        position: relative;
        display: inline-block;
        color: rgba(114, 132, 154, 0.7);
        &::before {
          position: absolute;
          top: 50%;
          left: 110%;
          width: 80px;
          height: 1px;
          background-color: #e6ebf1;
          content: '';
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 110%;
          width: 80px;
          height: 1px;
          background-color: #e6ebf1;
          content: '';
        }
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 4px;
      padding: 8.5px 11px;
      input {
        height: auto;
        border-radius: 0;
        background-color: transparent !important;
      }
    }
    .ant-col {
      &.ant-form-item-label {
        position: relative;
        font-weight: 600;
      }
    }
    .ant-input-prefix {
      .anticon {
        color: rgba(62, 121, 247, 1);
      }
    }
    .ant-form-item-explain-error {
      text-align: left;
      margin-bottom: 10px;
    }

    @media @sm {
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
  .auth__logo {
    img {
      height: 120px !important;
    }
  }
}
.login-img{
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem 5rem;
  text-align: center;
  display: flex !important;
  flex-direction: column;

  @media @md {
    display: none;
  }

  .globe{
    flex: 0 1 auto;
  }

  .welcome-text{
    flex: 1 1 auto;
  }

  h2.ant-typography{
    margin: 2rem 0 0.5rem 0;
    color: @white;
  }

  div.ant-typography{
    font-size: 16px;
    color: @white;
  }
}
