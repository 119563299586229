.ant-layout-sider {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 70px;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  z-index: 9;
  .ant-menu {
    background-color: transparent;
    li {
      svg {
        height: 15px;
        width: 16px;
        path {
          stroke: @menu-item-color;
        }
      }
      &.ant-menu-item-active {
        svg {
          path {
            stroke: @menu-highlight-color;
          }
        }
      }
      &.ant-menu-item-selected {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
  .anticon {
    font-size: 16px;
  }
  ul {
    height: 100%;
    .ant-menu-item {
      font-weight: 500;
    }
    .ant-menu-title-content {
      width: 100%;
      transition: all 0.5s ease;
      opacity: 1;
      visibility: visible;
      max-width: 100%;
    }
  }
  .ant-typography {
    padding: 15px 24px 10px;
    display: block;
  }
  &.ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      justify-content: center;
      padding: 0 16px !important;
      text-align: center;
    }
    .ant-menu-title-content,
    .-title-content {
      margin-left: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      max-width: 0;
      // display: none;
      display: inline-flex;
      // @include lg{
      //     max-width: 100%;
      //     opacity: 1;
      //     visibility: visible;
      //     margin-left: 10px;
      // }
    }
    .ant-typography {
      display: none;
    }
  }
  // @include lg{
  //     display: none;
  // }
}

.ant-menu-submenu-title {
  font-weight: 500;
}
.ant-menu-root {
  .ant-menu-item {
    font-weight: 400 !important;
  }
}

.ant-menu-submenu {
  .ant-menu-item {
    font-weight: 400 !important;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @active-menu-color;
  // color: #fff;
  border-radius: 0px 26px 26px 0px;
  width: 93%;
}

.sidenav-container {
  .ant-layout-sider {
    @media @lg {
      display: none;
    }
  }
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: @active-menu-link;
}

.ant-layout-sider-children {
  margin-top: 20px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  display: none;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  transition: none;
}

.ant-menu-item,
.ant-menu-submenu-title {
  transition: none;
}
