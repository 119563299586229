.logo {
  width: 100%;
  display: flex;
  justify-content: center;

  img{
    width: 550px;
    margin: 0 auto;
    height: 120px;
  }
}
.footer {
  // position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #0842a1;
  height: 100px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 20px;
  }
}

