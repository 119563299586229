.ml__auto {
  margin-left: auto;
}
.mr_auto {
  margin-right: auto;
}
.mt__auto {
  margin-top: auto;
}

.w__100 {
  width: 100% !important;
}

.w__80 {
  width: 80% !important;
}

.w__60 {
  width: 60% !important;
}

.h__auto{
  height: auto !important;
}

.mb__30 {
  margin-bottom: 30px !important;
}

.mb__20 {
  margin-bottom: 20px !important;
}

.mb__10 {
  margin-bottom: 10px !important;
}

.mb__0 {
  margin-bottom: 0px !important;
}

.mr__20 {
  margin-right: 20px !important;
}

.mr__30 {
  margin-right: 30px !important;
}

.mr__10 {
  margin-right: 10px !important;
}

.mt__30 {
  margin-top: 30px !important;
}

.mt__20 {
  margin-top: 20px !important;
}

.mt__10 {
  margin-top: 10px !important;
}

.mt__5{
  margin-top: 5px !important;
}

.ml__10 {
  margin-left: 10px !important;
}

.p__24 {
  padding: 24px !important;
}

.pt__20 {
  padding-top: 20px !important;
}
